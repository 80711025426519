<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label">Occurs*</label>
      <v-select
        :items="occurOptions"
        v-model="need.occurs"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      style="align-items: baseline"
      v-if="need.occurs == 'Daily' || need.occurs == 'Weekly'"
    >
      <label class="title-label">Repeat Every*</label>
      <v-select
        :items="repeatOptions"
        v-model="need.repeat_every"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      v-if="need.occurs == 'Weekly'"
    >
      <label class="title-label">Repeat On*</label>
      <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
        <v-checkbox v-model="need.repeat_on" label="Monday" value="Monday" />
        <v-checkbox v-model="need.repeat_on" label="Tuesday" value="Tuesday" />
        <v-checkbox
          v-model="need.repeat_on"
          label="Wednesday"
          value="Wednesday"
        />
        <v-checkbox
          v-model="need.repeat_on"
          label="Thursday"
          value="Thursday"
        />
        <v-checkbox v-model="need.repeat_on" label="Friday" value="Friday" />
        <v-checkbox
          v-model="need.repeat_on"
          label="Saturday"
          value="Saturday"
        />
        <v-checkbox v-model="need.repeat_on" label="Sunday" value="Sunday" />
      </div>
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      v-if="need.occurs == 'Monthly'"
    >
      <label class="title-label">Repeat By*</label>
      <v-select
        :items="repeatByOptions"
        v-model="need.repeat_by"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      v-if="need.occurs == 'Daily' || need.occurs == 'Weekly'"
    >
      <label class="title-label">
        Date Range*
      </label>
      <v-row>
        <v-col cols="6">
          <v-menu
            v-model="date_picker_start"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :top="$vuetify.breakpoint.xs"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="need.start_date"
                label="MM/DD/YYYY"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
              />
            </template>
            <v-date-picker v-model="startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date_picker_start = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onSaveStartDate(startDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="date_picker_end"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :top="$vuetify.breakpoint.xs"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="need.end_date"
                label="MM/DD/YYYY"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
              />
            </template>
            <v-date-picker v-model="endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date_picker_end = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onSaveEndDate(endDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      v-if="need.occurs == 'Daily' || need.occurs == 'Weekly'"
    >
      <label class="title-label">Start Time* </label>
      <v-menu
        v-model="time_picker"
        :close-on-content-click="false"
        :return-value.sync="startTime"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="need.start_time"
            label="Example: 15:00"
            :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
            solo
            flat
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            autocomplete="off"
          />
        </template>
        <v-time-picker v-model="startTime" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="date_picker_start = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="onSaveStartTime(startTime)">
            OK
          </v-btn>
        </v-time-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      ref="hours"
    >
      <label class="title-label"
        >Hours*
        <tip-icon>
          Enter the number of hours the volunteer is expected to work.<br />
          This number is used by your volunteer check-in feature.
          <br />For multi-date needs, Enter the average number of hours expected
          each day.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.hours"
        label="Example: 4 or 3.5"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
        :error-messages="hoursMessage"
        @input="onChange"
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Capacity*
        <tip-icon>
          Enter the number of volunteer spots available.
        </tip-icon>
      </label>
      <v-text-field
        v-model="need.capacity"
        label="Example: 30"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
        type="number"
      />
    </v-col>
  </v-row>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
export default {
  components: { TipIcon },
  props: {
    need: Object,
    hoursMessage: String,
    onChange: Function
  },
  data() {
    return {
      occurOptions: ["Daily", "Weekly", "Monthly"],
      repeatOptions: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30"
      ],
      repeatByOptions: ["Date", "Day of the Week"],
      startDate: null,
      endDate: null,
      startTime: null,
      date_picker_start: false,
      date_picker_end: false,
      time_picker: false
    };
  },
  methods: {
    changeDateFormat(dateStr) {
      return `${dateStr.substring(5, 7)}/${dateStr.substring(
        8,
        10
      )}/${dateStr.substring(0, 4)}`;
    },
    onSaveStartDate(dateStr) {
      this.need.start_date = this.changeDateFormat(dateStr);
      this.date_picker_start = false;
    },
    onSaveEndDate(dateStr) {
      this.need.end_date = this.changeDateFormat(dateStr);
      this.date_picker_end = false;
    },
    onSaveStartTime(timeStr) {
      this.need.start_time = timeStr;
      this.time_picker = false;
    }
  }
};
</script>
<style scoped>
.title-label {
  width: 200px;
}
</style>
