<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="d-flex flex-column h-100" v-if="need">
      <v-card-title class="d-flex flex-row justify-between">
        <span class="headline">Edit Need</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0 align-center d-flex flex-column flex-grow overflow-auto"
        ref="needContent"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Status*
                <tip-icon>
                  Tip: Be specific so that volunteers can understand your need
                  at a glance.
                </tip-icon>
              </label>
              <v-select
                :items="statusOptions"
                v-model="need.status"
                solo
                flat
                outlined
                dense
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              ref="title"
            >
              <label class="title-label"
                >Title*
                <tip-icon>
                  Tip: Be specific so that volunteers can understand your need
                  at a glance.
                </tip-icon>
              </label>
              <v-text-field
                v-model="need.title"
                label="Title"
                solo
                flat
                type="text"
                @input="onChange"
                required
                outlined
                dense
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                :error-messages="titleMessage"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Description*
                <tip-icon>
                  Provide a general description of the need, along with any
                  important details for the volunteer.
                </tip-icon>
              </label>
              <v-textarea
                v-model="need.description"
                rows="5"
                auto-grow
                @onChange="onChange"
                required
                solo
                flat
                outlined
                dense
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Privacy*
                <tip-icon>
                  Private needs can be accessed via<br />(1)A direct link or<br />(2)An
                  assigned user group. Make a need private to control access to
                  it; Make it public to recruit as widely as possible.
                </tip-icon>
              </label>
              <v-select
                :items="privacyItems"
                v-model="need.privacy"
                solo
                flat
                outlined
                dense
                label="Privacy"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              ref="duration"
            >
              <label class="title-label"
                >Duration*
                <tip-icon>
                  Happens on will display on calendar, are searchable by date
                  and expire on date indicated. Ongoing will never expire. Runs
                  until will expire on date indicated.
                </tip-icon>
              </label>
              <v-select
                :items="durationItems"
                v-model="need.duration"
                label="Duration"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
                :error-messages="durationMessage"
                @input="onChange"
                disabled
              />
            </v-col>
          </v-row>
          <agency-is-ongoing-input-form
            :need="need"
            v-if="need.duration == 'Is Ongoing'"
            :hoursMessage="hoursMessage"
            :onChange="onChange"
            ref="sub"
          />
          <agency-runs-until-input-form
            :need="need"
            v-else-if="need.duration == 'Runs Until'"
            :hoursMessage="hoursMessage"
            :onChange="onChange"
            ref="sub"
          />
          <agency-happens-on-input-form
            :need="need"
            v-else-if="need.duration == 'Happens On'"
            :hoursMessage="hoursMessage"
            :onChange="onChange"
            ref="sub"
          />
          <agency-multi-date-input-form
            :need="need"
            :hoursMessage="hoursMessage"
            v-else-if="need.duration == 'Multi-Date'"
            :onChange="onChange"
            ref="sub"
          />
          <agency-custom-shifts-input-form
            :need="need"
            v-else-if="need.duration == 'Custom Shifts'"
          />
          <agency-recurring-shifts-input-form
            :need="need"
            :hoursMessage="hoursMessage"
            :onChange="onChange"
            v-else-if="need.duration == 'Recurring Shifts'"
            ref="sub"
          />
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Allow Team Registration*
                <tip-icon>
                  Select 'Yes' to allow users to sign up multiple people in a
                  single response.<br />
                  Select 'No' to allow individual responses only.
                </tip-icon>
              </label>
              <v-select
                :items="allowTeamOptions"
                v-model="need.allow_team"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Minimum Age*
                <tip-icon>
                  If you leave this field blank but enter a maximum age below,
                  this field will display a '0' here and and 'And Younger' age
                  range in the volunteer view. <br />
                  Example: If the maximum age is 21, volunteer will see '21 and
                  younger'
                </tip-icon>
              </label>
              <v-text-field
                v-model="need.min_age"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Maximum Age*
                <tip-icon>
                  If you leave this field blank but enter a minimum age above,
                  this field will display a '120' here and and 'And up' age
                  range in the volunteer view. <br />
                  Example: If the minimum age is 21, volunteer will see '21 and
                  up'
                </tip-icon>
              </label>
              <v-text-field
                v-model="need.max_age"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Family Friendly?
                <tip-icon>
                  Select "Yes" if children and families are welcome. Note: if
                  you select "No", the need posting will state that the need is
                  "Not Family Friendly".<br />
                  If your need is not family-friendly but you want to avoid
                  including this statement, leave this field blank.
                </tip-icon>
              </label>
              <v-select
                :items="YesNoOptions"
                v-model="need.family_friendly"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Outdoor?
                <tip-icon>
                  Select "Yes" if this need will happen outdoors.
                </tip-icon>
              </label>
              <v-select
                :items="YesNoOptions"
                v-model="need.outdoors"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Wheelchair Accessible
                <tip-icon>
                  Select "Yes" if this need will happen in a Wheelchair
                  accessible venue.
                </tip-icon>
              </label>
              <v-select
                :items="YesNoOptions"
                v-model="need.wheelchair_accessible"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Attributes
                <tip-icon>
                  These details will be displayed at the bottom of the need
                  information page, separately from the need description.<br />
                  Use this area to have certain details (Such as "Snacks will be
                  provided" or "Wear closed-tie shoese") stand out. (200
                  characters maximum)
                </tip-icon>
              </label>
              <v-combobox
                v-model="need.attributes"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                multiple
                chips
                deletable-chips
                clearable
                style="width: 400px"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Virtual Need?
                <tip-icon>
                  Select "Yes" identifies this as sometime that can be done at
                  home or other off-site locations.
                </tip-icon>
              </label>
              <v-select
                :items="options"
                v-model="need.virtual_need"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">Require CORI report? </label>
              <v-select
                :items="options"
                v-model="need.cori_report"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">Images</label>
              <need-photo-viewer
                :editable="true"
                :image-data="need.images"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                :on-updated-image="onUpdatedImage"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">
                Address
                <tip-icon>
                  Address information is used to provide a map to interested
                  volunteers. Be as specific as possible when entering street,
                  city, state, and zip code.
                </tip-icon>
              </label>
              <div
                style="flex-grow: 1"
                :class="$vuetify.breakpoint.xs ? 'w-100' : ''"
              >
                <v-text-field
                  v-model="need.address"
                  label="Address"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                  solo
                  flat
                  outlined
                  dense
                />
                <v-text-field
                  v-model="need.address2"
                  label="Address 2"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                  solo
                  flat
                  outlined
                  dense
                />
                <v-text-field
                  v-model="need.city"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                  label="City"
                  solo
                  flat
                  outlined
                  dense
                />
                <v-select
                  v-model="need.state"
                  :items="stateOptions"
                  label="State"
                  :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                  solo
                  flat
                  outlined
                  dense
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Zip Code*
                <tip-icon>
                  If the need occurs across more than one zip code, use the
                  primary zip code or create separate needs.
                </tip-icon>
              </label>
              <v-text-field
                v-model="need.zip"
                label="Zip Code"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
                :error-messages="zipMessage"
                ref="zip"
                @input="onChange"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              ref="interests"
            >
              <label class="title-label"
                >Interests & Abilities*
                <tip-icon>
                  Your Selections will be used to match volunteers with needs
                  that interest them.<br />
                  The first item you choose will be the primary interest and
                  determines what icon is shown with this need. <br />To change
                  the primary interest, lick on the star of a different interest
                  you've selected.
                </tip-icon>
              </label>
              <v-combobox
                :items="interests"
                multiple
                chips
                deletable-chips
                hide-selected
                v-model="need.interests"
                label="Interests"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                auto-select-first
                style="width: 400px;"
                @input="onChangeInterest"
                :error-messages="interestsMessage"
                item-text="name"
                item-value="name"
              >
                <template v-slot:selection="{ attrs, item, selected }">
                  <v-chip
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    :input-value="selected"
                    small
                  >
                    <span class="pr-2">
                      {{ item }}
                    </span>
                    <v-icon small @click="onSelectPrimaryInterest(item)">
                      {{
                        item == need.primaryInterest
                          ? "mdi-star"
                          : "mdi-star-outline"
                      }}
                    </v-icon>
                    <v-icon small @click="deleteInterestItem(item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Accept volunteers response automatically?
              </label>
              <v-select
                :items="options"
                v-model="need.auto_accept"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-container>
        <div style="width: 100%">
          <small>*indicates required field</small>
        </div>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
import AgencyIsOngoingInputForm from "./AgencyIsOngoingInputForm.vue";
import AgencyRunsUntilInputForm from "./AgencyRunsUntilInputForm.vue";
import AgencyHappensOnInputForm from "./AgencyHappensOnInputForm.vue";
import AgencyMultiDateInputForm from "./AgencyMultiDateInputForm.vue";
import AgencyCustomShiftsInputForm from "./AgencyCustomShiftsInputForm.vue";
import AgencyRecurringShiftsInputForm from "./AgencyRecurringShiftsInputForm.vue";
import { mapActions, mapState } from "vuex";
import NeedPhotoViewer from "@/components/agency/NeedPhotoViewer.vue";
export default {
  components: {
    TipIcon,
    AgencyIsOngoingInputForm,
    AgencyRunsUntilInputForm,
    AgencyHappensOnInputForm,
    AgencyMultiDateInputForm,
    AgencyCustomShiftsInputForm,
    AgencyRecurringShiftsInputForm,
    NeedPhotoViewer
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onCloseDialog: {
      type: Function
    },
    item: Object
  },
  data() {
    return {
      error: null,
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: value => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: v => (v && v.length >= 6) || "Min 6 characters"
      },
      loading: false,
      privacyItems: ["Public", "Private"],
      durationItems: [
        "Is Ongoing",
        "Runs Until",
        "Happens On",
        "Multi-Date",
        "Custom Shifts",
        "Recurring Shifts"
      ],
      allowTeamOptions: ["No", "Yes", "Teams only"],
      YesNoOptions: ["Yes", "No", "N/A"],
      options: ["Yes", "No"],
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI"
      ],
      statusOptions: ["Active", "Pending", "Inactive"],
      snackbar: false,
      snackMessage: null,
      titleMessage: null,
      durationMessage: null,
      hoursMessage: null,
      zipMessage: null,
      interestsMessage: null,
      need: {}
    };
  },
  methods: {
    ...mapActions({
      editNeed: "need/editNeed",
      getInterestIcons: "icon/getInterestIcons"
    }),
    onClose() {
      this.need = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        end_date: "",
        images: []
      };
      this.onCloseDialog();
    },
    onSelectPrimaryInterest(item) {
      if (this.need.primaryInterest != item) {
        this.need.primaryInterest = item;
        this.need.interests = [...this.need.interests];
      }
    },
    deleteInterestItem(item) {
      if (this.need.primaryInterest == item) {
        this.snackbar = true;
        this.snackMessage =
          "You can not remove the primary interest. Please select another one as primary and try again.";
      } else {
        this.need.interests = this.need.interests.filter(it => it != item);
      }
    },
    onChange() {
      this.error = null;
      this.titleMessage = null;
      this.durationMessage = null;
      this.hoursMessage = null;
      this.zipMessage = null;
      this.interestsMessage = null;
    },
    onUpdatedImage(images) {
      this.need.images = images;
      this.error = null;
      this.titleMessage = null;
      this.durationMessage = null;
      this.hoursMessage = null;
      this.zipMessage = null;
      this.interestsMessage = null;
    },
    onSave() {
      this.error = null;
      this.titleMessage = null;
      this.durationMessage = null;
      if (!this.need.title) {
        this.titleMessage = "required";
        this.$vuetify.goTo(
          this.$refs.title,
          {
            container: this.$refs.needContent
          },
          500
        );
        return;
      }
      if (!this.need.duration) {
        this.durationMessage = "required";
        this.$vuetify.goTo(
          this.$refs.duration,
          {
            container: this.$refs.needContent
          },
          500
        );
        return;
      }
      if (this.need.duration != "Custom Shifts" && !this.need.hours) {
        this.hoursMessage = "required";
        this.$vuetify.goTo(
          this.$refs.sub.$refs.hours,
          {
            container: this.$refs.needContent
          },
          500
        );

        return;
      }
      if (!this.need.zip) {
        this.zipMessage = "required";
        this.$vuetify.goTo(
          this.$refs.zip,
          {
            container: this.$refs.needContent
          },
          500
        );
        return;
      }
      if (!this.need.interests || this.need.interests.length == 0) {
        this.interestsMessage = "required";
        this.$vuetify.goTo(
          this.$refs.interests,
          {
            container: this.$refs.needContent
          },
          500
        );
        return;
      }
      this.loading = true;
      this.editNeed(this.need)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    onChangeInterest() {
      this.onChange();
      if (
        this.need.interests &&
        this.need.interests.length > 0 &&
        !this.need.primaryInterest
      ) {
        this.need.primaryInterest = this.need.interests[0];
      }
    }
  },
  mounted() {
    this.need = { ...this.item };
    this.getInterestIcons().catch(error => console.log(error));
  },
  watch: {
    item(newValue) {
      this.need = newValue;
    },
    dialog() {
      this.need = this.item;
    }
  },
  computed: {
    ...mapState("icon", {
      interests: "interestNames"
    })
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    padding-top: 17px;
    padding-bottom: 17px;
    height: 100%;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 998 !important;
  }
  div :deep(.v-dialog) {
    height: 80vh;
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.title-label {
  width: 200px;
}

.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}
</style>
