<template>
  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <v-card>
      <v-card-text
        class="pb-0 pt-12 d-flex flex-row justify-center"
        style="align-items: center; position:relative"
      >
        <v-icon size="24" color="#E2574C" v-text="'mdi-alert'" class="mr-3" />
        <div
          style="font-size: 20px; font-family:'Poppins-Medium'; color:#1e1e1e"
          v-text="'Do you want to eliminate this need?'"
        />
        <v-btn
          icon
          style="position: absolute; top: 10px; right:10px"
          @click="onClose"
          ><v-icon color="black">mdi-close</v-icon></v-btn
        >
      </v-card-text>
      <v-card-text class="mt-5 justify-center d-flex">
        All relative schedules and activities will be removed. This action
        cannot be undone.
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="blue white"
          @click="onClose"
          class="white--text"
          v-text="'Cancel'"
          width="100"
        />
        <v-btn
          color="red white"
          @click="onConfirm"
          class="white--text ml-8"
          :loading="loading"
          width="100"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    deleteDialog: {
      type: Boolean,
      default: false
    },
    need: Object,
    onClose: {
      type: Function
    },
    onDeleted: Function
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions("need", {
      deleteNeed: "deleteNeed"
    }),
    onConfirm() {
      this.deleteNeed({ _id: this.need._id })
        .then(() => {
          this.onDeleted();
        })
        .catch(error => {
          this.snackbar = true;
          this.errorMessage = error.data.message;
        });
    }
  }
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
